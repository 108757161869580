import React from 'react';
import { Button } from 'semantic-ui-react';

import styles from './homeTitle.module.scss';


function HomeTitle() {
	return (
		<div className={styles["hero__container"]}>
			<div className={styles["hero__image__container"]}>
				<div className={`${styles["hero__image"]} ease-up-immediate`}/>
			</div>
			<div className={`${styles["hero__banner_container"]} ease-up-immediate`}>		
				<p className={styles["core__heading"]} style={{paddingTop: '20px'}}>
					<b><i>Unrivalled access </i></b>to New Zealand's growth economy.
				</p>
				<p className={styles["core__paragraph"]}>
				The stock exchange connecting everyday investors and Kiwi businesses with big potential.
				</p>
				<p>
					<Button 
						style={{marginRight: '20px'}} 
						color="blue" 
						href='/investors'>
							Grow my portfolio 
					</Button>
					<Button 
						style={{marginTop: '40px'}}
						className={'custom-orange-btn'}
						href='/business-solutions'>
							Grow my business
					</Button>
				</p>
			</div>
		</div>
	)
}

export default HomeTitle